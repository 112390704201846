@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 0 0% 98%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;

    /* Header */
    --header-height: 106px;
    @media (min-width: 768px) {
      --header-height: 124px;
    }
    @media (min-width: 1280px) {
      --header-height: 56px;
    }

    /* Subheader */
    --subheader-height: 56px;

    /* Footer */
    --footer-height: 80px;
    @media (min-width: 768px) {
      --footer-height: 45px;
    }

    /* Package Header /packages/[name]/[tag]/[variant]/* */
    --package-header-height: 150px;
    @media (min-width: 768px) {
      --package-header-height: 165px;
    }
    --package-nav-height: 50px;

    --package-code-contracts-nav-height: 36px;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 5.9% 10%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground font-sans antialiased min-h-screen w-screen;
  }

  body.fouc-prevention {
    background-color: rgb(14, 17, 22);
  }
  body.fouc-prevention * {
    display: none !important;
  }
}

[data-section] > pre {
  @apply p-4;
}

[data-rk] ._9pm4ki3 {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 2147483646;
}

/* react-diff-view styles */
.diff {
  font-family: var(--diff-font-family);
  color: var(--diff-text-color);
  background-color: var(--diff-background-color);
}

.diff-gutter {
  padding: 0 1em;
  cursor: pointer;
  text-align: right;
  background-color: var(--diff-background-color);
}

.diff-code {
  padding: 0 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Light mode */
.diff-line.diff-line-add {
  background-color: rgba(0, 200, 0, 0.1);
}

.diff-line.diff-line-add .diff-gutter {
  background-color: rgba(0, 200, 0, 0.15);
}

.diff-line.diff-line-delete {
  background-color: rgba(255, 0, 0, 0.1);
}

.diff-line.diff-line-delete .diff-gutter {
  background-color: rgba(255, 0, 0, 0.15);
}

/* Dark mode */
.dark .diff-line.diff-line-add {
  background-color: rgba(0, 200, 0, 0.15);
}

.dark .diff-line.diff-line-add .diff-gutter {
  background-color: rgba(0, 200, 0, 0.2);
}

.dark .diff-line.diff-line-delete {
  background-color: rgba(255, 0, 0, 0.15);
}

.dark .diff-line.diff-line-delete .diff-gutter {
  background-color: rgba(255, 0, 0, 0.2);
}
